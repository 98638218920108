import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { IQueryStatusItem } from "../QueriesInProgress/QueryItem"
import moment from "moment"
import "moment/locale/fr"

interface QueryStatusItemProps {
    query: IQueryStatusItem
    tabIndex: number
}

const QueryStatusItem: React.FC<QueryStatusItemProps> = ({ query, tabIndex }) => {
    const { language, t } = useI18next()
    moment.locale(language)

    return (
        <div className="query-status-item">
            <div className="title1">{t('Query & Therapeutic Area')}</div>
            {query.url && <a href={query.url} target="_blank">{query.title}</a>}
            {!query.url && <div className="title2">{query.title}</div>}
            <div className="subtitle">{query.therapeuticArea ?? 'N/A'}</div>

            <div className="title1 mt-4">{t('Reponse team(s) & Start date')}</div>
            <div className="section mt-2">
                <img src="/images/home/ic_team.svg" alt="team" width={23} height={21} />
                <p>{query.responseTeam ?? 'N/A'}</p>
            </div>

            <div className="section">
                <img src="/images/home/ic_calendar.svg" alt="status" width={22} height={22} />
                <p>{moment(query.startDate).format('D-MMM-YY')}<span>&nbsp;&nbsp;({query.status ?? 'N/A'})</span></p>
            </div>

            <div className="title1 mt-8">{tabIndex === 0 ? t('Projected Completion') : t('Completion Date')}</div>
            <div className="section mt-2">
                <img src="/images/home/ic_complete.svg" alt="status" width={29} height={29} />
                <p>{query.projectedCompletion ?? 'N/A'}</p>
            </div>
        </div>
    )
}

export default QueryStatusItem