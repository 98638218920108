import React, { useEffect } from "react";
import Landing from "../components/Landing";
import { graphql}  from "gatsby";
import AOS from "aos";
import "aos/dist/aos.css";
interface HomeProps {

}

const HomePage: React.FC<HomeProps> = ({}) => {
  useEffect(() => { AOS.init({ duration: 2000 }); }, []);
  
  return (
    <div>
      <Landing />
    </div>
  )
}

export default HomePage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <>
    <title>CoLab</title>
    <meta name="description" content="CoLab is a pan-Canadian evidence-generation network comprised of leading experts in applied research, drug evaluation methodology, and data analysis." />
    <meta name="image" content="/images/favicon-16x16.png" />
    <link rel="icon" href="/images/favicon-16x16.png" type="image/png" sizes="16x16"></link>
  </>
)