import React, { useEffect, useState } from "react";
import { useI18next } from 'gatsby-plugin-react-i18next';
import QueryStatusSelect, { DropdownOption } from "@src/components/shared/navigations/QueryStatusSelect";
import QueryStatusItem, { IQueryStatusItem } from "./QueryItem";
import axios from "axios";
import moment from "moment";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

export interface QueriesInProgressProps {
    className?: string
}

const QueriesInProgress: React.FC<QueriesInProgressProps> = ({
    className = ''
}) => {
    const { language, t } = useI18next()
    moment.locale(language)

    let options: DropdownOption[] = [
        { id: 1, value: 'Most recent', label: t('Most recent'), selected: true, isDisabled: false },
        { id: 2, value: 'Active', label: t('Active'), selected: false, isDisabled: false },
        { id: 3, value: 'On Hold', label: t('On Hold'), selected: false, isDisabled: true },
        { id: 4, value: 'Delayed', label: t('Delayed'), selected: false, isDisabled: true },
        { id: 5, value: 'Received', label: t('Received'), selected: false, isDisabled: true },
    ];

    const [tabIndex, setTabIndex] = useState(0)
    const [queries, setQueries] = useState<IQueryStatusItem[]>([])
    const [selectedQueries, setSelectedQueries] = useState<IQueryStatusItem[]>([])
    const [activePage, setActivePage] = useState(0)
    const [pages, setPages] = useState(1)
    const [currentFilter, setCurrentFilter] = useState('Most recent')
    const [lastUpdate, setLastUpdate] = useState('')
    const [selectOptions, setSelectOptions] = useState(options)
    const [keyword, setKeyWord] = useState('')
    const [selectedQueryId, setSelectedQueryId] = useState(null)

    const filterQueries = () => {
        let selectedQueries = queries.filter(query => tabIndex === 1 ? query.status === 'Complete' :
            currentFilter === 'Most recent' ? query.status !== 'Complete' : query.status === currentFilter)

        if (currentFilter === 'Most recent') {
            selectedQueries = selectedQueries.sort((query1, query2) =>
                new Date(query2.startDate).getTime() - new Date(query1.startDate).getTime()
            )
        }

        if (selectedQueryId !== null) {
            selectedQueries = selectedQueries.filter(query => query.id === selectedQueryId)
        }
        setPages(Math.ceil(selectedQueries.length / 3))
        setSelectedQueries(selectedQueries.slice(activePage * 3, activePage * 3 + 3))
    }

    useEffect(() => {
        setActivePage(0)
        filterQueries()
    }, [tabIndex, currentFilter])

    useEffect(() => {
        filterQueries()
    }, [activePage, selectedQueryId])

    useEffect(() => {
        const fetchQueries = async () => {
            try {
                const res = await axios.get('/query-status')
                const queries = language === 'en' ? res.data.en : res.data.fr
                const selectedQueries = queries.filter(query => query.status != 'Complete')
                    .sort((query1, query2) => new Date(query2.startDate).getTime() - new Date(query1.startDate).getTime()
                    )
                setQueries(queries)
                setSelectedQueries(selectedQueries)
                setPages(Math.ceil(selectedQueries.length / 3))
                setSelectedQueries(selectedQueries.slice(activePage * 3, activePage * 3 + 3))
                setLastUpdate(res.data.lastUpdated ? moment(res.data.lastUpdated).format('DD-MMM-YYYY') : 'N/A')

                options[2].isDisabled = selectedQueries.filter(query => query.status === 'On Hold').length === 0
                options[3].isDisabled = selectedQueries.filter(query => query.status === 'Delayed').length === 0
                options[4].isDisabled = selectedQueries.filter(query => query.status === 'Received').length === 0
                setSelectOptions(options)
            } catch (error) {
                console.log(error)
            }
        }
        fetchQueries()
    }, [])

    const handleOnSearch = (string, _) => {
        setKeyWord(string)

        if (string === '') {
            setSelectedQueryId(null)
        }
    }

    const handleOnSelect = (item) => {
        setSelectedQueryId(item.id)
        setTabIndex(item.status !== "Complete" ? 0 : 1)
    }

    const formatResult = (item) => {
        let reg = new RegExp(`(${keyword})`, "gi")
        let titleHTML = item.title.replaceAll(reg, "<strong>$&</strong>")
        let teamHTML = moment(item.startDate).format('D-MMM-YY') +
            `<span>&nbsp;&nbsp;(${item.status ?? 'N/A'})</span> &nbsp; &nbsp; &nbsp; (${t('response team')}) &nbsp;` +
            item.responseTeam.replaceAll(reg, "<strong>$&</strong>")

        return (
            <div className="search-query-item">
                <div className="title" dangerouslySetInnerHTML={{ __html: titleHTML }} />
                <p dangerouslySetInnerHTML={{ __html: teamHTML }} />
            </div>
        )
    }

    return (
        <div className={`queries-in-progress-wrapper ${className}`} id={t('query-status')}>
            <div className="section-container-wrapper">
                <div className="title">{t('Query Status')}</div>
                <div className="query-search-container">
                    <ReactSearchAutocomplete
                        items={queries}
                        fuseOptions={{
                            keys: ["title", "responseTeam"],
                            includeMatches: true,
                            threshold: 0.0,
                            ignoreLocation: true,
                            minMatchCharLength: 2
                        }}
                        resultStringKeyName=""
                        onSearch={handleOnSearch}
                        onSelect={handleOnSelect}
                        formatResult={formatResult}
                        placeholder={t("Find queries / response team...")}
                        showIcon={false}
                        showClear={false}
                        showNoResults={false}
                        styling={{
                            zIndex: 10,
                            height: "40px",
                            border: "none",
                            boxShadow: "none",
                            backgroundColor: "white",
                            fontSize: "13px",
                            placeholderColor: "#696969",
                            color: "#009CDE",
                            lineColor: "white",
                            fontFamily: "Roboto"
                        }}
                        className={`query-search ${language}`}
                        // maxLength={40}
                        maxResults={5}
                    />
                    <div className={`search-icon ${language}`} />
                </div>
                <div className={`header ${language}`} id="query-table">
                    <div className={`tab-container ${language}`}>
                        <div className={`title-tab ${tabIndex === 0 ? 'active' : ''}`}
                            onClick={() => {
                                options.forEach(option => option.selected = false)
                                options[0].selected = true
                                setTabIndex(0)
                            }}>{t('Ongoing')}</div>
                        <div className={`title-tab ${tabIndex === 1 ? 'active' : ''}`}
                            onClick={() => {
                                options.forEach(option => option.selected = false)
                                options[0].selected = true
                                setTabIndex(1)
                            }}>{t('Complete')}</div>
                    </div>
                    <QueryStatusSelect
                        tabIndex={tabIndex}
                        options={tabIndex === 0 ? selectOptions : [selectOptions[0]]}
                        onSelect={(value: string) => setCurrentFilter(value)} />
                </div>
                <div className="title-table">
                    <span className={language}>{t('Query & Therapeutic Area')}</span>
                    <span className={language}>{t('Reponse team(s) & Start date')}</span>
                    <span className={language}>{tabIndex === 0 ? t('Projected Completion') : t('Completion Date')}</span>
                </div>
                <div className="query-status-items">
                    {
                        selectedQueries.map((query) => <QueryStatusItem query={query} />)
                    }
                    {
                        selectedQueries.length === 0 &&
                        <div className="empty-section"><p>{t('No query found')}</p></div>
                    }
                </div>
                <div className="table-footer">
                    <div className="pages">
                        {
                            Array.from(Array(pages).keys()).map((page) =>
                                <div className={`page ${page == activePage ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setActivePage(page)
                                        document.getElementById('query-table')?.scrollIntoView({ behavior: 'smooth' })
                                    }}>{page + 1}</div>
                            )
                        }
                    </div>
                    <p className="last-update">{`${t('Last updated')}: ${lastUpdate}`}</p>
                </div>
                <p className="footer-text">{t('All non-confidential ongoing and completed queries are listed here. For any questions related to these queries, please contact us at ')}<a href="mailto:colab@cadth.ca" target="_blank">CoLab@cadth.ca</a>.</p>
            </div>
        </div>
    )
}

export default QueriesInProgress