import React, { useState } from "react";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { useI18next } from 'gatsby-plugin-react-i18next';

interface DropdownOption {
  value: string;
  label: string;
  selected: boolean;
}

const DropdownIndicator = (
  props: DropdownIndicatorProps<DropdownOption, true>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src="/images/home/ic_dropdown.svg" />
    </components.DropdownIndicator>
  );
};


const LanguageSelect: React.FC = ({
}) => {
  const { language, changeLanguage } = useI18next();

  let languageOptions: readonly DropdownOption[] = [
    { value: 'en', label: 'EN', selected: language == 'en' },
    { value: 'fr', label: 'FR', selected: language == 'fr' }
  ]
  
  const [defaultLanguage, setDefaultLanguage] = useState<DropdownOption>(language == 'en' ? languageOptions[0] : languageOptions[1]);

  const onChange = (data: any) => {
    changeLanguage(data.value);

    if (data.value == 'en') {
      languageOptions[0].selected = true
    } else {
      languageOptions[1].selected = true
    }
  }

  return (
    <Select
      className="language-select"
      classNamePrefix="language-select"
      defaultValue={defaultLanguage}
      options={languageOptions}
      components={{ DropdownIndicator }}
      isSearchable={false}
      onChange={onChange} />
  )
};

export default LanguageSelect