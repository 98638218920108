import React from "react"

import "../../../scss/components/_nav.scss"

interface MenuWrapperProps {
  className?: string,
  children: JSX.Element[]
}

const MenuWrapper: React.FC<MenuWrapperProps> = ({
  className = '',
  children
}) => {
  return (
    <ul className={`menu-wrapper ${className}`}>
      {children}
    </ul>
  )
}

export default MenuWrapper