import React, { useEffect, useState } from "react";
import Select, { components, DropdownIndicatorProps } from "react-select";

export interface DropdownOption {
  id: number
  value: string
  label: string
  selected: boolean
  isDisabled: boolean
}

const DropdownIndicator = (
  props: DropdownIndicatorProps<DropdownOption, true>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src="/images/home/ic_dropdown.svg" />
    </components.DropdownIndicator>
  );
};

interface QueryStatusSelectProps {
  options: DropdownOption[]
  onSelect(value: string) : void
  tabIndex: number
}

const QueryStatusSelect: React.FC<QueryStatusSelectProps> = ({ tabIndex, options, onSelect }) => {
  const [value, setValue] = useState(options[0])
  const [selectOptions, setSelectOptions] = useState(options)

  useEffect(() => {
    setSelectOptions(options)
    setValue(options[0])
  }, [tabIndex])
  
  const onChange = (data: any) => {
    options[data.id - 1].selected = true
    setSelectOptions(options)
    setValue(data)
    onSelect(data.value)
  }

  return (
    <Select
      className="query-status-select"
      classNamePrefix="query-status-select"
      value={value}
      options={selectOptions}
      components={{ DropdownIndicator }}
      isSearchable={false}
      onChange={onChange} />
  )
};

export default QueryStatusSelect