import React, { useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { useI18next } from 'gatsby-plugin-react-i18next';

interface PartnerItem {
    name: String,
    type: String,
    description: String
}

interface NetworkMapMobileProps {
    className?: string
}

interface DropdownOption {
    value: string | number;
    label: string
}

export interface SliderDot {
    index: number,
    selected: boolean
}

const DropdownIndicator = (
    props: DropdownIndicatorProps<DropdownOption, true>
) => {
    return (
        <components.DropdownIndicator {...props}>
            <img src="/images/home/ic_dropdown.svg" />
        </components.DropdownIndicator>
    );
};

const NetworkMapMobile: React.FC<NetworkMapMobileProps> = ({
    className = ''
}) => {
    const { language, t } = useI18next();
    const slider = useRef(null);

    const networkType1 = t("Core Network Partner");
    const networkType2 = t("Network Collaborator");

    const options: readonly DropdownOption[] = [
        { value: 0, label: t("Show all partners") },
        { value: 1, label: t("CNODES") },
        { value: 2, label: t("PODET") },
        { value: 3, label: t("ADTEC") },
        { value: 4, label: t("CCRE Platform") },
        { value: 5, label: t("CAN-AIM") },
        { value: 6, label: t("Exactis") },
        { value: 7, label: t("ActionADE") },
        { value: 8, label: "Dr. Michael Law" }
    ]

    const sliderDotsData: SliderDot[] = [
        { index: 0, selected: false },
        { index: 1, selected: false },
        { index: 2, selected: false },
        { index: 3, selected: true },
        { index: 4, selected: false },
        { index: 5, selected: false },
        { index: 6, selected: false },
        { index: 7, selected: false },
        { index: 8, selected: false }
    ]

    const [sliderDots, setSliderDots] = useState<SliderDot[]>(sliderDotsData);

    const onChange = (data: any) => {
        if (slider.current) {
            (slider.current as Slider).slickGoTo(data.value - 1, true);
        }
    }

    const onNext = () => {
        if (slider.current) {
            (slider.current as Slider).slickNext();
        }
    }

    const onPrevious = () => {
        if (slider.current) {
            (slider.current as Slider).slickPrev();
        }
    }

    const settings: Settings = {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5000,
        swipeToSlide: true,
        initialSlide: 3,
        pauseOnFocus: true,
        beforeChange: (_, index) => {
            let newData: SliderDot[] = [];
            sliderDotsData.forEach((_, itemIndex) => {
                newData.push({ index: itemIndex, selected: index == itemIndex })
            })
            setSliderDots(newData);
        }
    };

    const partners: PartnerItem[] = [
        {
            name: t("CNODES"),
            type: networkType1,
            description: t("Over the past 10 years, the Canadian Network for Observational Drug Effect Studies (CNODES) has built a network of more than 100 scientists to study the real-world use, safety, and effectiveness of drugs used by people in Canada.\nCNODES has access to administrative health care data on millions of medication users across Canada and internationally.\nCNODES uses state-of-the-art observational research methods and rapid response studies plus semi-automated tools and a common data model for standardized analyses."),
        },
        {
            name: t("PODET"),
            type: networkType1,
            description: t("The POst-Market Drug Evaluation Team (PODET) comprises a diverse team of investigators with established expertise in systematic reviews, rapid reviews, living systematic reviews, scoping reviews, meta-analyses, network-meta-analyses, health technology assessments, health system and clinical practice guidance, health economics, pharmacovigilance, post-market drug evaluation, and evidence application and evaluation.\nThis team has a history of sustained successful collaborations with different partners, including WHO, PHAC, Health Canada, Canadian Partnership Against Cancer, Cancer Care Ontario, and the CADTH pan-Canadian Oncology Drug Review.")
        },
        {
            name: t("ADTEC"),
            type: networkType1,
            description: t("The Alberta Drug and Technology Evaluation Consortium (ADTEC) comprises research leaders from the University of Alberta, the University of Calgary, and the Institute of Health Economics who have demonstrated experience and expertise in the execution of decision-grade research.\nADTEC leverages access to high-quality population-based administrative data in Alberta and expertise in epidemiological methods, policy evaluation, and health care costing and economics.")
        },
        {
            name: t("CCRE Platform"),
            type: networkType1,
            description: t("The Canadian Cancer Real-World Evaluation (CCRE) Platform comprises Canada's leading researchers in real-world evidence in cancer who are uniquely embedded within cancer agencies in Ontario, Alberta, and British Columbia.\nThis team has extensive experience answering decision-maker questions.\nCCRE can leverage its role as a data owner and custodian to respond rapidly to decision-maker questions by directly accessing and linking oncology-related health administrative databases.\nThis team is built on well-established collaborations with organizations such as Ontario Health (Cancer Care Ontario), Alberta Health Services, BC Cancer Agency, the Canadian Association of Provincial Cancer Agencies, Canadian Centre for Applied Research in Cancer Control, Health Data Research Network Canada, and the Canadian Institute for Health Information.")
        },
        {
            name: t("CAN-AIM"),
            type: networkType2,
            description: t("The Canadian Network for Advanced Interdisciplinary Methods (CAN-AIM) enhances the validity and accuracy of Canadian research on the real-life comparative effectiveness and safety of drugs by producing efficient, relevant, and client-focused results.\nThis team’s innovative methodologies use complex longitudinal data on exposures, covariates, and outcomes, which enables them to explore and understand the associations and risks in robust ways that can be communicated clearly to decision-makers.")
        },
        {
            name: t("Exactis"),
            type: networkType2,
            description: t("Exactis Innovation (Exactis) is a not-for-profit organization whose main initiative is the pan-Canadian Personalize My Treatment (PMT) Network.\nIt includes 16 hospitals across 5 provinces, dedicated onsite study coordinators, and a patient registry with more than 7,000 cancer patients from which clinical, molecular, and survival data are collected.\nThe Exactis-PMT Network team is experienced in generating evidence to support reimbursement decisions and brings fast access to real-world data through its registry and chart review across its sites.")
        },
        {
            name: t("ActionADE"),
            type: networkType2,
            description: t("ActionADE includes 50 hospitals in 8 provinces and collects data on consecutively confirmed COVID-19 cases presenting to emergency departments.\nActionADE accrues data on 2,500 plus patients per week, including hospital course, outpatient and inpatient medications, interventions, and health outcomes (e.g., quality of life).\nThis team can collect patient-level data to inform decision-making about drug treatments for different target diseases.")
        },
        {
            name: t("Dr. Michael Law"),
            type: networkType2,
            description: t("Dr. Michael Law holds the Canada Research Chair in Access to Medicines and is a professor in the Centre for Health Services and Policy Research, School of Population and Public Health, at the University of British Columbia.\nDr. Law’s research program focuses on pharmaceutical policy, with a focus on access to and the use of prescription drugs, including the affordability of prescription drugs, private drug insurance, generic drug pricing, and the impact of pharmacist prescribing.")
        }
    ];

    return (
        <div className={`network-map-mobile-wrapper ${className}`} id={`${t('meet-the-colab-network')}-m`}>
            <div className="title">{t('CoLab Network')}</div>
            <div className={`description ${language}`}>{t('CoLab is made up of a diverse group of experts from across Canada. Review the map to learn more about our core network partners and network collaborators.')}</div>
            <img className="img-map" src="/images/home/network_map.svg" height={304} alt="network_map" data-aos="fade-in" />
            <Select
                className="partner-select mt-8"
                classNamePrefix="partner-select"
                defaultValue={options[0]}
                options={options}
                components={{ DropdownIndicator }}
                isSearchable={false}
                onChange={onChange}
                placeholder={t('Show all partners')} />

            <Slider ref={slider} className={`partner-slider`} {...settings}>
                {partners.map(({ name, type, description }) => (
                    <div key={`${name}`} className="partner-card">
                        {name == 'D<sup>r</sup> Michael Law' && <div className="title occean">D<sup>r</sup> Michael Law</div>}
                        {name != 'D<sup>r</sup> Michael Law' && <div className="title occean">{name}</div>}
                        <div className="subtitle">{type}</div>
                        <div className="mt-6">
                            {description?.split("\n").map(line => <p key={line} className="description">{line}</p>)}
                        </div>
                    </div>
                ))}
            </Slider>

            <div className="slider-section">
                <div className="dot-buttons">
                    {sliderDots.map(({ index, selected }) => (
                        <div key={index} className={selected ? "filled-dot" : "empty-dot"} />
                    ))}
                </div>
                <div className="slide-buttons" data-aos="fade-in">
                    <button className="prev-button" onClick={onPrevious}></button>
                    <button className="next-button" onClick={onNext}></button>
                </div>
            </div>
        </div>
    )
}

export default NetworkMapMobile;