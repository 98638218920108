import React from "react";
import { useI18next } from 'gatsby-plugin-react-i18next';

interface OurGoalsProps {
    className?: string
}

const OurGoals: React.FC<OurGoalsProps> = ({
    className = ''
}) => {
    const { language, t } = useI18next();

    return (
        <div className={`our-goals-wrapper ${className} ${language}`} id={t('what-is-colab')}>
            <div className="section-container-wrapper">
                <div className="container">
                <div className="section-left">
                    <div className="title-text">{t('CoLab is a pan-Canadian evidence-generation network comprised of leading experts in applied research, drug evaluation methodology, and data analysis.')}</div>
                    <div className="description-text">{t('Ensuring access to safe, effective, and clinically relevant drugs is important for Canada’s health systems. CoLab answers questions about postmarket drug safety and effectiveness for senior policy decision-makers with timely targeted evidence.')}</div>
                    <div className="description-text">{t('CoLab receives funding from Canada’s Drug Agency (CDA-AMC) through the ')}
                        <a href={t('https://www.cda-amc.ca/post-market-drug-evaluation-pmde-program')} target="_blank">{t('Post-Market Drug Evaluation (PMDE) Program')}</a>
                        {t('. Postmarket drug evaluation focuses on drugs that have proceeded through the approval process and are available to patients and consumers. It helps determine if drugs used in the real-world are safe, work as intended, and are used appropriately.')}
                    </div>
                </div>
                <div className={`our-goals ${language}`}>
                    <div className={`title-text ${language}`}>{t('Our Goals')}</div>
                    <div className="our-goal-row first">
                        <div className="number-text">1.</div>
                        <div className="description-text">{t(`Answer decision-makers' questions using best-in-class approaches.`)}</div>
                    </div>
                    <div className="our-goal-row second">
                        <div className="number-text">2.</div>
                        <div className="description-text">{t('Work collaboratively within our pan-Canadian network and with international partners.')}</div>
                    </div>
                    <div className="our-goal-row second">
                        <div className="number-text">3.</div>
                        <div className="description-text">{t('Bring decision-makers rigorous evidence according to their timelines.')}</div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default OurGoals