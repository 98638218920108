import React from "react";
import { useI18next } from 'gatsby-plugin-react-i18next';

interface RangeOfServicesProps {
    className?: string
}

const RangeOfServices: React.FC<RangeOfServicesProps> = ({
    className = ''
}) => {
    const { language, t } = useI18next();

    return (
        <div className={`range-of-services-wrapper ${className} ${language}`}>
            <div className="section-container-wrapper">
                <div className="title-text">{t(`Through CoLab, the Post-Market Drug Evaluation Program provides a wide range of services for Canada's federal, provincial, and territorial decision-makers.`)}</div>
                <div className={`container ${language}`}>
                    <div className="service-column">
                        <img src="/images/home/ic_analysis.svg" className="img1" alt="analysis" data-aos="fade-in" />
                        <div className="title-text">{t('Data Analysis and Observational Research')}</div>
                        <div className={`description-text ${language === 'fr' ? 'xl:!mt-[60px]' : ''}`}>{t('Use of pan-Canadian data to allow for postmarket evaluation. Targeted safety and effectiveness analyses for therapeutic products. Observational studies to generate new evidence.')}</div>
                    </div>
                    <div className="service-column">
                        <img src="/images/home/ic_studies.svg" className="img2" alt="data" data-aos="fade-in" />
                        <div className="title-text">{t('Formulary Management Support')}</div>
                        <div className="description-text">{t('Health technology assessment of available postmarket evidence, including rapid reviews, systematic reviews, and health economics. Horizon scanning of the pharmaceutical landscape to proactively identify and address health system needs.')}</div>
                    </div>
                    <div className="service-column">
                        <img src="/images/home/ic_knowledge_translation.svg" className="img3" alt="studies" data-aos="fade-in" />
                        <div className="title-text">{t('Knowledge Translation')}</div>
                        <div className="description-text md:!mt-10 xl:!mt-[60px]">{t('Creation of summary documents and custom knowledge translation tools to support knowledge sharing across a range of audiences.')}</div>
                    </div>
                    {/* <div className="service-column">
                        <img src="/images/home/ic_knowledge_translation.svg" className="img4" alt="knowledge_translation" data-aos="fade-in" />
                        <div className="title-text">{t('Knowledge Translation')}</div>
                        <div className="description-text">{t('Integrate knowledge translation and mobilization initiatives to leverage the evidence produced through CoLab and expand its potential impact and benefit.')}</div>
                    </div>
                    <div className="service-column">
                        <img src="/images/home/ic_formulary_support.svg" className="img5" alt="forumlary_support" data-aos="fade-in" />
                        <div className="title-text">{t('Formulary Support')}</div>
                        <div className="description-text">{t('Provide support for formulary management and harmonization for pharmaceuticals across Canada.')}</div>
                    </div>
                    <div className="service-column">
                        <img src="/images/home/ic_monitoring.svg" className="img6" alt="monitoring" data-aos="fade-in" />
                        <div className="title-text">{t('Monitoring')}</div>
                        <div className="description-text">{t('Monitor the pharmaceutical landscape to better position jurisdictions to proactively address impending health system issues.')}</div>
                    </div> */}
                </div>
            </div>

        </div>
    )
}

export default RangeOfServices