import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import MenuWrapper from "../MenuWrapper";
import MenuItem from "../MenuItem";
import { Link } from "gatsby";

interface MobileMenuProps {
    display: string,
    onCloseMenu: () => void
}

const NavigationMenu: React.FC<MobileMenuProps> = ({ display, onCloseMenu }) => {
    const { t } = useI18next();

    const onClick = () => {
        let menu = document.getElementById("menu-navigation");

        if (menu) {
            menu.classList.toggle("close");
        }
        onCloseMenu();
    }

    const onClickTimeout = () => {
        setTimeout(onClick, 100);
    };

    return (
        <div className={`menu-navigation ${display}`} id="menu-navigation">
            <div className='menu'>
                <div className='jump-to'>{t('Jump to...')}</div>
                <button className='btn-close' onClick={onClick}>
                    <img src='/images/home/ic_close.svg' width={21} height={21} alt='close' />
                </button>
                <MenuWrapper className="nav-items">
                    <MenuItem onClick={onClickTimeout}>
                        <Link className='nav-item' to={`#${t('what-is-colab')}`}>{t('What is CoLab?')}</Link>
                    </MenuItem>
                    <MenuItem onClick={onClickTimeout}>
                        <Link className='nav-item' to={`#${t('how-it-works')}`}>{t('How It Works')}</Link>
                    </MenuItem>
                    <MenuItem onClick={onClickTimeout}>
                        <Link className='nav-item' to={`#${t('meet-the-colab-network')}-m`}>{t('Meet the CoLab Network')}</Link>
                    </MenuItem>
                    <MenuItem onClick={onClickTimeout}>
                        <Link className='nav-item' to={`#${t('submit-a-query')}`}>{t('Submit a Query')}</Link>
                    </MenuItem>
                    <MenuItem onClick={onClickTimeout}>
                        <Link className='nav-item' to={`#${t('query-status')}-m`}>{t('Query Status')}</Link>
                    </MenuItem>
                    <MenuItem onClick={onClickTimeout}>
                        <Link className='nav-item' to={`#${t('contact-us')}`}>{t('Contact Us')}</Link>
                    </MenuItem>
                </MenuWrapper>
                <div className='bottom-logo'>
                    <img src='/images/home/colab_logo.webp' width={140} height={48} alt="logo" />
                </div>
            </div>
        </div>
    )
};

export default NavigationMenu;