import { useEffect, useRef, useState, useMemo } from "react";
type IntervalFunction = () => (unknown | void);

export const validateEmail = (email: string) => {
    const emailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return email.match(emailformat);
}

export const useInterval = (callback: IntervalFunction, delay: number) => {

  const savedCallback = useRef<IntervalFunction | null>(null)

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  })

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current()
      }
    }
    const id = setInterval(tick, delay)
    return () => clearInterval(id)

  }, [delay])
}

export const useIsInViewport = (ref) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  let observer;

  useEffect(() => {
    observer = new IntersectionObserver(([entry]) =>
      setIsIntersecting(entry.isIntersecting)
    )
  }, []);

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

