import React from "react";
import { Link } from "gatsby";
import { useI18next } from 'gatsby-plugin-react-i18next';

interface UsefulLinksProps {
    className?: string
}

const UsefulLinks: React.FC<UsefulLinksProps> = ({
    className = ''
}) => {
    const { language, t } = useI18next();

    return (
        <div className={`useful-links-wrapper ${className}`} id={t('contact-us')} >
            {/* <div className="flex flex-row justify-between bg-blue-1">
                <div className="news-section basis-1/3">
                    <div className="flex flex-column">
                        <div className="title-text">News</div>
                        <Link className="link-button" to="https://www.cadth.ca/news-and-events/news" target="_blank">
                            <img src="/images/home/ic_next_round.webp" alt="ic_next" />
                        </Link>
                    </div>
                    <div className="description-text">Find stories and articles from across the post-market drug evaluation landscape here.</div>
                </div>
                <div className="events-section basis-1/3">
                    <div className="flex flex-column">
                        <div className="title-text">Events</div>
                        <Link className="link-button" to="https://www.cadth.ca/news-and-events/events" target="_blank">
                            <img src="/images/home/ic_next_round.webp" alt="ic_next" />
                        </Link>
                    </div>
                    <div className="description-text">Find upcoming events here.</div>
                </div>
                <div className="contact-us-section basis-1/3">
                    <div className="flex flex-column">
                        <div className="title-text">Contact us</div>
                        <Link className="link-button" to="https://www.cadth.ca/news-and-events/events" target="_blank">
                            <img src="/images/home/ic_next_round.webp" alt="ic_next" />
                        </Link>
                    </div>
                    <div className="description-text">CoLab is headquartered in Ottawa, with our partners and network collaborators located throughout Canada. We can be reached at colab@cadth.ca.</div>
                </div>
            </div> */}

            <div className="contact-us-launch-section">
                <div className="section-container-wrapper flex flex-col justity-center items-center">
                    <div className="flex flex-column">
                        <div className="title-text">{t('Contact Us')}</div>
                        <Link className="link-button" to="mailto:colab@cadth.ca" target="_blank">
                            <img src="/images/home/ic_next_round.webp" width={55} alt="ic_next" data-aos="fade-in" />
                        </Link>
                    </div>
                </div>
                <div className={`description-text ${language}`}>{t('CoLab is managed by the PMDE Program, headquartered in Ottawa. CoLab partners and network collaborators are located throughout Canada. We can be reached at ')}
                    <a href="mailto:colab@cadth.ca" target="_blank">colab@cadth.ca</a>.
                </div>
            </div>
            <div className="useful-links">
                <div className="section-container-wrapper">
                    <div className="title-text mt-18 text-center">{t('Useful Links')}</div>
                    <div className="cadth-buttons">
                        <Link to={t('https://www.cda-amc.ca/')} target="_blank">
                            <div className="cadth-button">
                                <div className="mt-5">{t('CDA-AMC home page')}</div>
                                <img src="/images/home/ic_next.svg" width={22} height={22} alt="next" data-aos="fade-in" />
                            </div>
                        </Link>
                        <Link to={t('https://www.cda-amc.ca/post-market-drug-evaluation-pmde-program')} target="_blank">
                            <div className="cadth-button">
                                <div className="mt-5">{t('PMDE Program')}</div>
                                <img src="/images/home/ic_next.svg" width={22} height={22} alt="next" data-aos="fade-in" />
                            </div>
                        </Link>
                        <Link to={t('https://www.cda-amc.ca/real-world-evidence-decision-making')} target="_blank">
                            <div className="cadth-button">
                                <div className="mt-5">{t('Real-World Evidence Program')}</div>
                                <img src="/images/home/ic_next.svg" width={22} height={22} alt="next" data-aos="fade-in" />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsefulLinks;