import React, { useState } from "react";

import { Form, Field } from 'react-final-form';

import ReCAPTCHA from 'react-google-recaptcha';

import { useI18next } from 'gatsby-plugin-react-i18next';

import locale1 from 'antd/es/date-picker/locale/en_US';
import 'moment/locale/fr-ca';
import locale2 from 'antd/es/date-picker/locale/fr_CA';

export interface SubmitQueryProps {
    className?: string
}

const SubmitQuery: React.FC<SubmitQueryProps> = ({
    className = ''
}) => {
    const { language, t } = useI18next();

    return (
        <div className={`submit-query-wrapper ${className} ${language}`} id={t('submit-a-query')}>
            <div className="header">
                <div className="title-text">{t('Submit a Query')}</div>
            </div>
            <div className="section-container-wrapper">
                <div className="query-form-header">
                    <div className="left-section">
                        <div className={`title ${language}`}>{t('Query Submission Form')}</div>
                        <div className="subtitle">{t('To submit a query to CoLab, complete the online Query Request Form (link follows) and select the following option for question #4:')}</div>
                        <ul>
                            <li className={language}>{t('Decision related to the safe and appropriate use of drugs approved by Health Canada and currently available to people living in Canada (a postmarket drug evaluation).')}</li>
                        </ul>
                        <a href={`${process.env.GATSBY_FORM_SUBMIT_URL}&lang=${language === 'fr' ? 'fr-CA' : 'en-US'}`} className="form-link" target="_blank">{t('Query Request Form')}</a>
                        <div className="description">{t('Queries should be about a health care decision or policy issue related to the safe and appropriate use of drugs. Once your query has been received, a member of our team will contact you.')}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubmitQuery
