import React from 'react'
import LanguageSelect from '../LanguageSelect';

interface MobileNavProps {
    onClick: () => void
}

const MobileNav: React.FC<MobileNavProps> = ({ onClick }) => {
    return (
        <div className='mobile-nav'>
            <div className='header'>
                <div className='logo' data-aos="fade-in">
                    <img src='/images/home/colab_logo.webp' width={140} height={48} alt="logo" />
                </div>
                <LanguageSelect />
                <button className='btn-hamburgur' onClick={onClick}>
                    <img src='/images/home/ic_hamburgur.svg' width={45} height={45} alt='hamburgur' />
                </button>
                <div></div>
            </div>
        </div>
    )
};

export default MobileNav;