import { Link } from "gatsby";
import React, { useState } from "react";

import MenuWrapper from "../MenuWrapper";
import MenuItem from "../MenuItem";

import LanguageSelect from "../LanguageSelect";

import { useI18next } from 'gatsby-plugin-react-i18next';

interface MainNavProps {
    className?: string
}

const MainNav: React.FC<MainNavProps> = ({
    className = ''
}) => {
    const { language, t } = useI18next();
    const [selectedIndex, setSelectedIndex] = useState(0);

    return (
        <div className={`main-nav ${className} ${language}`}>
            <MenuWrapper className="justify-between lg:justify-evenly w-full">
                <MenuItem className="lg:block" onClick={() => setSelectedIndex(0)}>
                    <Link className={`nav-item ${selectedIndex == 0 ? 'active' : ''}`} to={`#${t('what-is-colab')}`}>{t('What is CoLab?')}</Link>
                </MenuItem>
                <MenuItem className="lg:block" onClick={() => setSelectedIndex(1)}>
                    <Link className={`nav-item ${selectedIndex == 1 ? 'active' : ''}`} to={`#${t('how-it-works')}`}>{t('How It Works')}</Link>
                </MenuItem>
                <MenuItem className="lg:block" onClick={() => setSelectedIndex(2)}>
                    <Link className={`nav-item ${selectedIndex == 2 ? 'active' : ''}`} to={`#${t('meet-the-colab-network')}`}>{t('Meet the CoLab Network')}</Link>
                </MenuItem>
                <div className="lg:block">
                    <Link to="/">
                        <div key="logo" className="logo" data-aos="fade-in" />
                    </Link>
                </div>
                <MenuItem className={`${selectedIndex == 3 ? 'active' : ''}`} onClick={() => setSelectedIndex(3)}>
                    <Link className={`nav-item ${selectedIndex == 3 ? 'active' : ''}`} to={`#${t('submit-a-query')}`}>{t('Submit a Query')}</Link>
                </MenuItem>
                <MenuItem className={`${selectedIndex == 4 ? 'active' : ''}`} onClick={() => setSelectedIndex(4)}>
                    <Link className={`nav-item ${selectedIndex == 4 ? 'active' : ''}`} to={`#${t('query-status')}`}>{t('Query Status')}</Link>
                </MenuItem>
                <MenuItem className={`${selectedIndex == 5 ? 'active' : ''}`} onClick={() => setSelectedIndex(5)}>
                    <Link className={`nav-item ${selectedIndex == 5 ? 'active' : ''}`} to={`#${t('contact-us')}`}>{t('Contact Us')}</Link>
                </MenuItem>
            </MenuWrapper>
            <LanguageSelect />
        </div>
    )
};

export default MainNav;