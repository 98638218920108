import React, { useState, useMemo, useRef, useEffect } from "react";
import _ from "lodash";
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useInterval, useIsInViewport } from "@src/utils/utils";

interface HowItWorksProps {
    className?: string
}

const HowItWorks: React.FC<HowItWorksProps> = ({
    className = ''
}) => {
    const { language, t } = useI18next();

    const [isScroll, setScroll] = useState(false);
    const scrollRef = useRef<HTMLDivElement>(null);
    const parentRef = useRef<HTMLDivElement>(null);
    const [isSrollBack, setIsSrollBack] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const isInViewPort = useIsInViewport(scrollRef);
    const [shouldScroll, setShouldScroll] = useState(false);
    const hoverRef = useRef(isHover);

    let touchEndTimeout;
    let scrollBackTimeout;

    const onScroll = useMemo(() => {
        const throttled = _.throttle(e => setScroll(e.target.scrollLeft > 0), 300);
        return e => {
            e.persist();
            return throttled(e);
        };
    }, []);

    const onMouseOverHandle = () => {
        setIsHover(true);
        hoverRef.current = true;
    }

    const onMouseLeaveHandle = () => {
        setIsHover(false);
        hoverRef.current = false;
    }

    const onTouchStart = (e) => {
        if (e) {
            clearTimeout(touchEndTimeout);
            setIsHover(true);
            hoverRef.current = true;
        }
    }

    const onTouchMove = (e) => {
        e.preventDefault();
    }

    const onTouchEnd = () => {
        touchEndTimeout = setTimeout(() => {
            setIsHover(false);
            hoverRef.current = false;
        }, 2000)
    }

    const onTouchCancel = () => {
        touchEndTimeout = setTimeout(() => {
            setIsHover(false);
            hoverRef.current = false;
        }, 2000)
    }

    useEffect(() => {
        if (parentRef.current) {
            parentRef.current.addEventListener("touchstart", onTouchStart, { passive: false });
            parentRef.current.addEventListener("touchmove", onTouchMove, { passive: false });
            parentRef.current.addEventListener("touchend", onTouchEnd);
            parentRef.current.addEventListener("touchcancel", onTouchCancel);
        }

        return () => {
            if (parentRef.current) {
                parentRef.current.removeEventListener("touchstart", onTouchStart);
                parentRef.current.removeEventListener("touchmove", onTouchMove);
                parentRef.current.removeEventListener("touchend", onTouchEnd);
                parentRef.current.removeEventListener("touchcancel", onTouchCancel);
            }
        }
    }, [])

    useInterval(() => {
        setShouldScroll(isInViewPort);
    }, 2000);

    useInterval(() => {
        if (isSrollBack || isHover || !shouldScroll || scrollBackTimeout) {
            return;
        }

        if (scrollRef.current) {
            if (scrollRef.current.scrollLeft < (scrollRef.current.scrollWidth - scrollRef.current.offsetWidth - 1.0)) {
                scrollRef.current.scrollLeft += 2;
            } else {
                scrollBackTimeout = setTimeout(() => {
                    if (scrollRef.current && !hoverRef.current) {
                        scrollRef.current.scrollTo({
                            left: 0,
                            top: 0,
                            behavior: 'smooth'
                        });
                        setIsSrollBack(true);
                        setTimeout(() => {
                            setIsSrollBack(false);
                        }, 3000);
                    }
                }, 2000);
            }
        }
    }, 20);

    return (
        <div className={`how-it-works-wrapper ${className}`} id={t('how-it-works')}>
            <div className="section-container-wrapper">
                <div className={`title-text ${language}`}>{t('How It Works')}</div>
                <div className="description-text">{t('Explore each step of our process:')}</div>
                <div className={`scroll-card-container ${isScroll ? "scroll" : ""}`}
                    onScroll={onScroll}
                    ref={scrollRef}
                    id="scroll-card">
                    <div className="card-container"
                        ref={parentRef}
                        onMouseLeave={onMouseLeaveHandle}
                        onMouseOver={onMouseOverHandle}>
                        <div className="card short first">
                            <div className="div-number">1</div>
                            <div className="card-content">
                                <img className="img1" src="/images/home/ic_how_it_works_1.png" alt="how_it_works" data-aos="fade-in" />
                                <div className={`card-text ${language} first`}>{t('Query submission')}</div>
                            </div>
                        </div>
                        <img className="card-arrow" src="/images/home/arrow_line.svg" alt="arrow-line" />
                        <div className="card short">
                            <div className="div-number">2</div>
                            <div className="card-content">
                                <img className="img2" src="/images/home/ic_how_it_works_2.png" alt="how_it_works" data-aos="fade-in" />
                                <div className={`card-text ${language}`}>{t('Scoping and refinement')}</div>
                                <ul className={`card-ul xl:mt-8 mt-4 ${language}`}>
                                    <li className={`card-li ${language}`}>
                                        {t('Topic brief')}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <img className="card-arrow" src="/images/home/arrow_line.svg" alt="arrow-line" />
                        <div className="card short">
                            <div className="div-number">3</div>
                            <div className="card-content">
                                <img className="img3" src="/images/home/ic_how_it_works_3.png" alt="how_it_works" data-aos="fade-in" />
                                <div className={`card-text ${language}`}>{t('Query response team engagement and feasibility assessment')}</div>
                            </div>
                        </div>
                        <img className="card-arrow" src="/images/home/arrow_line.svg" alt="arrow-line" />
                        <div className={`card long ${language}`}>
                            <div className="div-number">4</div>
                            <div className={`card-content ${language}`}>
                                <img className="img4" src="/images/home/ic_how_it_works_4.png" alt="how_it_works" data-aos="fade-in" />
                                <div className={`card-text ${language}`}>{t('Delivery of draft protocols and plans')}</div>
                                <ul className={`card-ul xl:mt-5 mt-3 ${language}`}>
                                    <li className={`card-li ${language}`}>
                                        {t('Query protocol')}
                                    </li>
                                    <li className={`card-li ${language}`}>
                                        {t('SAP')}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <img className="card-arrow" src="/images/home/arrow_line.svg" alt="arrow-line" />
                        <div className="card short">
                            <div className="div-number">5</div>
                            <div className="card-content">
                                <img className="img5" src="/images/home/ic_how_it_works_5.png" alt="how_it_works" data-aos="fade-in" />
                                <div className={`card-text ${language}`}>{t('Evidence generation and analysis')}</div>
                            </div>
                        </div>
                        <img className="card-arrow" src="/images/home/arrow_line.svg" alt="arrow-line" />
                        <div className="card short">
                            <div className="div-number">6</div>
                            <div className="card-content card-6">
                                <img className="img6" src="/images/home/ic_how_it_works_6.png" alt="how_it_works" data-aos="fade-in" />
                                <div className={`card-text ${language}`}>{t('Interpretation of evidence and findings')}</div>
                                <ul className="card-ul xl:mt-4 mt-2">
                                    <li className={`card-li ${language}`}>
                                        {t('Scientific report')}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <img className="card-arrow" src="/images/home/arrow_line.svg" alt="arrow-line" />
                        <div className="card short">
                            <div className="div-number">7</div>
                            <div className="card-content">
                                <img className="img7" src="/images/home/ic_how_it_works_7.png" alt="how_it_works" data-aos="fade-in" />
                                <div className={`card-text ${language}`}>{t('Knowledge dissemination')}</div>
                                <ul className="card-ul xl:mt-6 mt-4">
                                    <li className={`card-li ${language === 'en' ? 'xl:w-32 w-[100px]' : ''} ${language}`}>
                                        {t('Knowledge mobilization tools')}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <img className="card-arrow" src="/images/home/arrow_line.svg" alt="arrow-line" />
                        <div className="card short">
                            <div className="div-number">8</div>
                            <div className="card-content">
                                <img className="img8" src="/images/home/ic_how_it_works_8.png" alt="how_it_works" data-aos="fade-in" />
                                <div className={`card-text ${language}`}>{t('Follow-up for impact')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowItWorks;