import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";

import MainNav from "../shared/navigations/MainNav";
import HeaderCard from "./HeaderCard";
import OurGoals from "./OurGoals";
import HowItWorks from "./HowItWorks";
import EnsuringAccess from "./EnsuringAccess";
import RangeOfServices from "./RangeOfServices";
import UsefulLinks from "./UsefulLinks";
import Customers from "./Customers";
import NetworkMap from "./NetworkMap";
import NetworkMapMobile from "./NetworkMapMobile";
import SubmitQuery from "./SubmitQuery";
import Footer from "./Footer";
import QueriesInProgress from "./QueriesInProgress";
import MobileNav from "../shared/navigations/MobileNav";
import NavigationMenu from "../shared/navigations/NavigationMenu";
import QueriesInProgressMobile from "./QueryInProgressMobile";

const Landing: React.FC<RouteComponentProps<any>> = ({}) => {
    const [display, setDisplay] = useState('close');
    const [whiteBackground, setWhiteBackground] = useState(false);
    const [scrollToTopVisible, setScrollToTopVisible] = useState(false);

    const onClick = () => {
        const body = document.querySelector('body');

        if (display == 'close') {
            setDisplay('open');

            if (body) {
                body.style.overflow = 'hidden';
            }
        } else {
            setDisplay('close');

            if (body) {
                body.style.removeProperty('overflow');
            }
        }
    };

    const onCloseMenu = () => {
        const body = document.querySelector('body');
        setDisplay('close');

        if (body) {
            body.style.removeProperty('overflow');
        }
    }

    const onScrollToTop = () => {
        document.documentElement.style.scrollBehavior = "smooth";
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
    }

    useEffect(() => {
        const onScroll = () => {
            if (window.pageYOffset >= 100) {
                setWhiteBackground(true);
            } else {
                setWhiteBackground(false);
            }

            if (window.pageYOffset >= 600) {
                setScrollToTopVisible(true);
            } else {
                setScrollToTopVisible(false);
            }
        };
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <div className="landing-wrapper w-screen flex flex-col">
            <NavigationMenu display={display} onCloseMenu={onCloseMenu} />
            <MainNav className={`${whiteBackground ? 'bg-white' : ''}`} />
            <MobileNav onClick={onClick} />
            <HeaderCard />
            <OurGoals />
            <HowItWorks />
            {/* <EnsuringAccess /> */}
            <RangeOfServices />
            <Customers />
            <NetworkMap />
            <NetworkMapMobile />
            <SubmitQuery />
            <QueriesInProgress />
            <QueriesInProgressMobile />
            <UsefulLinks />
            <Footer />
            <button className={`btn-top ${scrollToTopVisible ? 'show' : 'hide'}`} onClick={onScrollToTop}></button>
        </div>
    )
}

export default Landing