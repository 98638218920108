import React, { useEffect, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import { useI18next } from 'gatsby-plugin-react-i18next';
import QueryStatusSelect, { DropdownOption } from "@src/components/shared/navigations/QueryStatusSelect";
import QueryStatusItem from "./QueryItem";
import { QueriesInProgressProps } from "../QueriesInProgress";
import { IQueryStatusItem } from "../QueriesInProgress/QueryItem";
import { SliderDot } from "../NetworkMapMobile";
import axios from "axios";
import moment from "moment";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

const QueriesInProgressMobile: React.FC<QueriesInProgressProps> = ({
    className = ''
}) => {
    const { language, t } = useI18next()

    let options: DropdownOption[] = [
        { id: 1, value: 'Most recent', label: t('Most recent'), selected: true, isDisabled: false },
        { id: 2, value: 'Active', label: t('Active'), selected: false, isDisabled: false },
        { id: 3, value: 'On Hold', label: t('On Hold'), selected: false, isDisabled: true },
        { id: 4, value: 'Delayed', label: t('Delayed'), selected: false, isDisabled: true },
        { id: 5, value: 'Received', label: t('Received'), selected: false, isDisabled: true },
    ];

    const [tabIndex, setTabIndex] = useState(0)
    const [queries, setQueries] = useState<IQueryStatusItem[]>([])
    const [selectedQueries, setSelectedQueries] = useState<IQueryStatusItem[]>([])
    const [currentFilter, setCurrentFilter] = useState('Most recent')
    const [sliderDots, setSliderDots] = useState<SliderDot[]>(
        selectedQueries.map((_, index) => { return { index: index, selected: index === 0 } })
    );
    const [lastUpdate, setLastUpdate] = useState('')
    const [selectOptions, setSelectOptions] = useState(options)
    const [keyword, setKeyWord] = useState('')
    const [selectedQueryId, setSelectedQueryId] = useState(null)

    const slider = useRef(null)

    const filterQueries = () => {
        let selectedQueries = queries.filter(query => tabIndex === 1 ? query.status === 'Complete' :
            currentFilter === 'Most recent' ? query.status !== 'Complete' : query.status === currentFilter)

        if (currentFilter === 'Most recent') {
            selectedQueries = selectedQueries.sort((query1, query2) =>
                new Date(query2.startDate).getTime() - new Date(query1.startDate).getTime()
            )
        }

        if (selectedQueryId !== null) {
            selectedQueries = selectedQueries.filter(query => query.id === selectedQueryId)
        }
        setSelectedQueries(selectedQueries)
        setSliderDots(selectedQueries.map((_, index) => { return { index: index, selected: index === 0 } }))
    }

    useEffect(() => {
        filterQueries()
    }, [tabIndex, currentFilter, selectedQueryId])

    useEffect(() => {
        const fetchQueries = async () => {
            try {
                const res = await axios.get('/query-status')
                const queries = language === 'en' ? res.data.en : res.data.fr
                const selectedQueries = queries.filter(query => query.status != "Complete")
                setQueries(queries)
                setSelectedQueries(selectedQueries.sort((query1, query2) =>
                    new Date(query2.startDate).getTime() - new Date(query1.startDate).getTime())
                )
                setLastUpdate(res.data.lastUpdated ? moment(res.data.lastUpdated).format('DD-MMM-YYYY') : 'N/A')

                options[2].isDisabled = selectedQueries.filter(query => query.status === 'On Hold').length === 0
                options[3].isDisabled = selectedQueries.filter(query => query.status === 'Delayed').length === 0
                options[4].isDisabled = selectedQueries.filter(query => query.status === 'Received').length === 0

                setSelectOptions(options)
            } catch (error) {
                console.log(error)
            }
        }
        fetchQueries()
    }, [])

    const handleOnSearch = (string, _) => {
        setKeyWord(string)

        if (string === '') {
            setSelectedQueryId(null)
        }
    }

    const handleOnSelect = (item) => {
        setSelectedQueryId(item.id)
        setTabIndex(item.status !== "Complete" ? 0 : 1)

        if (document.activeElement) {
            (document.activeElement as HTMLElement).blur()
        }
    }

    const formatResult = (item) => {
        let reg = new RegExp(`(${keyword})`, "gi")
        let titleHTML = item.title.replaceAll(reg, "<strong>$&</strong>")
        let teamHTML = moment(item.startDate).format('D-MMM-YY') +
            `<span>&nbsp;&nbsp;(${item.status ?? 'N/A'})</span> &nbsp; &nbsp; (${t('response team')}) &nbsp;` +
            item.responseTeam.replaceAll(reg, "<strong>$&</strong>")

        return (
            <div className="search-query-item">
                <div className="title" dangerouslySetInnerHTML={{ __html: titleHTML }} />
                <p dangerouslySetInnerHTML={{ __html: teamHTML }} />
            </div>
        )
    }

    const settings: Settings = {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        // autoplay: false,
        // speed: 1000,
        // autoplaySpeed: 5000,
        swipeToSlide: true,
        initialSlide: 0,
        // pauseOnFocus: true,
        beforeChange: (_, index) => {
            let newData: SliderDot[] = []
            selectedQueries.forEach((_, itemIndex) => {
                newData.push({ index: itemIndex, selected: index == itemIndex })
            })
            setSliderDots(newData)
        }
    };

    return (
        <div className={`queries-in-progress-mobile-wrapper ${className}`} id={`${t('query-status')}-m`}>
            <div className="section-container-wrapper">
                <div className="title">{t('Query Status')}</div>
                <div className="query-search-container">
                    <ReactSearchAutocomplete
                        items={queries}
                        fuseOptions={{
                            keys: ["title", "responseTeam"],
                            includeMatches: true,
                            threshold: 0.0,
                            ignoreLocation: true,
                            minMatchCharLength: 2
                        }}
                        resultStringKeyName=""
                        onSearch={handleOnSearch}
                        onSelect={handleOnSelect}
                        formatResult={formatResult}
                        placeholder={t("Find queries / response team...")}
                        showIcon={false}
                        showClear={false}
                        showNoResults={false}
                        styling={{
                            zIndex: 10,
                            height: "40px",
                            border: "none",
                            boxShadow: "none",
                            backgroundColor: "white",
                            fontSize: "13px",
                            placeholderColor: "#696969",
                            color: "#009CDE",
                            lineColor: "white",
                            fontFamily: "Roboto"
                        }}
                        className={`query-search ${language}`}
                        // maxLength={40}
                        maxResults={5}
                    />
                    <div className={`search-icon ${language}`} />
                </div>
                <div className={`header ${language}`}>
                    <div className="tab-container">
                        <div className={`title-tab ${tabIndex === 0 ? 'active' : ''}`}
                            onClick={() => {
                                options.forEach(option => option.selected = false)
                                options[0].selected = true
                                setTabIndex(0)
                            }}>{t('Ongoing')}</div>
                        <div className={`title-tab ${tabIndex === 1 ? 'active' : ''}`}
                            onClick={() => {
                                options.forEach(option => option.selected = false)
                                options[0].selected = true
                                setTabIndex(1)
                            }}>{t('Complete')}</div>
                    </div>
                    <QueryStatusSelect
                        tabIndex={tabIndex}
                        options={tabIndex === 0 ? selectOptions : [selectOptions[0]]}
                        onSelect={(value: string) => setCurrentFilter(value)} />
                </div>
                <Slider ref={slider} className={`queries-slider`} {...settings}>
                    {
                        selectedQueries.map((query) => <><QueryStatusItem query={query} tabIndex={tabIndex} /></>)
                    }
                    {
                        selectedQueries.length === 0 &&
                        <div className="empty-section"><p>{t('No query found')}</p></div>
                    }
                </Slider>

                <div className="slider-section">
                    <div className="dot-buttons">
                        {
                            sliderDots.map(({ index, selected }) => (
                                <div key={index} className={selected ? "filled-dot" : "empty-dot"} />
                            ))
                        }
                    </div>
                </div>
                <p className="last-update">{`${t('Last updated')}: ${lastUpdate}`}</p>
                <p className="footer-text">{t('All non-confidential ongoing and completed queries are listed here. For any questions related to these queries, please contact us at ')}<a href="mailto:colab@cadth.ca" target="_blank">CoLab@cadth.ca</a>.</p>
            </div>
        </div>
    )
}

export default QueriesInProgressMobile