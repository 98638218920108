import React from "react"

import "../../../scss/components/_nav.scss"

interface MenuItemProps {
  className?: string,
  children: JSX.Element,
  onClick?: () => void
}

const MenuItem: React.FC<MenuItemProps> = ({
  className = '',
  children,
  onClick
}) => {
  return (
    <li className={`menu-item ${className}`} onClick={onClick}>
      {children}
    </li>
  )
}

export default MenuItem