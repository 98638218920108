import React from "react";
import { useI18next } from 'gatsby-plugin-react-i18next';

interface HeaderCardProps {
    className?: string
}

const HeaderCard: React.FC<HeaderCardProps> = ({
    className = ''
}) => {
    const { language, t } = useI18next();

    const logoSrc = language == "en" ? "/images/home/cadth_logo_with_text.svg" : "/images/home/fr/cadth_logo_with_text.svg";

    const onClick = () => {
        document.location = language == 'en' ? `/#${t('submit-a-query')}` : `/fr/#${t('submit-a-query')}`
    }

    return (
        <div className={`header-wrapper ${className}`}>
            <div className="section-container-wrapper ">
                <div className="flex flex-col">
                    <div className={`title-text ${language}`}>{t('Canada’s Leader in Postmarket Drug Evaluation')}</div>
                    <div className={`description-text ${language}`}>{t('The CoLab Network provides credible and timely evidence needed to answer questions from Canada’s senior federal, provincial, and territorial health care decision-makers about postmarket drug safety and effectiveness.')}</div>
                    <button className={`button-query ${language}`} onClick={onClick}>{t('Submit a query')}</button>
                </div>
                {/* <img className="cadth-logo" src={logoSrc} alt="cadth_logo" data-aos="fade-in" /> */}
            </div>
        </div>
    )
}

export default HeaderCard