import moment from "moment"
import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import "moment/locale/fr"

export interface IQueryStatusItem {
    id: number
    title?: string
    url?: string
    therapeuticArea?: string
    responseTeam?: string
    startDate: string
    projectedCompletion?: string
    status?: string
}

interface QueryStatusItemProps {
    query: IQueryStatusItem
}

const QueryStatusItem: React.FC<QueryStatusItemProps> = ({ query }) => {
    const { language } = useI18next()
    moment.locale(language)

    return (
        <div className="query-status-item">
            <div className="section1">
                {query.url && <a href={query.url} target="_blank">{query.title}</a>}
                {!query.url && <div className="title1">{query.title}</div>}
                <div className="subtitle">{query.therapeuticArea ?? 'N/A'}</div>
            </div>
            <div className="divider"/>
            <div className="section1">
                <div className="section2">
                    <img src="/images/home/ic_team.svg" alt="team" width={23} height={21} />
                    <p>{query.responseTeam ?? 'N/A'}</p>
                </div>
                <div className="section2">
                    <img src="/images/home/ic_calendar.svg" alt="status" width={22} height={22} />
                    <p>{moment(query.startDate).format('D-MMM-YY')}<span>&nbsp;&nbsp;({query.status ?? 'N/A'})</span></p>
                </div>
            </div>
            <div className="divider"/>
            <div className="section1">
                <div className="section2">
                    <img src="/images/home/ic_complete.svg" alt="status" width={29} height={29} />
                    <p>{query.projectedCompletion ?? 'N/A'}</p>
                </div>
            </div>
        </div>
    )
}

export default QueryStatusItem